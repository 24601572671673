/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import { keyBy, mapValues } from 'lodash';

// api, store
import selectActiveInstrument from 'store/selectors/selectActiveInstrument';
import * as api from '../api';

// components
import ProductSelectorDropdown from 'shared/ProductSelectorDropdown';
import IndexWeightsTable from './components/IndexWeightsTable';
import RebalanceTable from './components/RebalanceTable';
import TableHeader from './components/TableHeader';
import TableFooter from './components/TableFooter';
import RebalanceDetails from './components/RebalanceDetails';
import NoResults from 'shared/NoResults';
import Loading from 'shared/Loading';

// utils, helpers
import { getTradeStrategy } from 'utils/tradeStrategy';
import { compareNumbers } from 'utils/numbers';
import get from 'lodash/get';
import { groupWeightsByUnderlying } from './helpers/groupWeightsByUnderlying';
import { every } from 'lodash';

const mapStateToProps = state => ({
  activeInstrument: selectActiveInstrument(state),
  currentUser: state.session.data.user,
});

const renderRebalanceTable = ({
  activeInstrument,
  handleOnChange,
  handleOnClickApproval,
  loading,
  rebalances,
  weights,
  selectedDate,
  rebalanceDates,
  updating,
  isExecutable,
  tradeWasExecuted,
  refreshRebalanceValues,
  isRebalanceToday
}) => {
  const totalActualPostWeight = rebalances.reduce((totalWeights, rebalance) => {
    totalWeights += Number(rebalance.actualPostWeight);
    return totalWeights;
  }, 0);

  const rebalanceDatesSet = new Set(rebalanceDates);
  const isApproved = rebalances.find(rebalance => rebalance.approvedAt)
  let isReadyForApproval = rebalanceDatesSet.has(selectedDate) && !isApproved && !updating && compareNumbers(totalActualPostWeight, 1, 4);
  const isEditable = !isApproved && !(formatDate(selectedDate, 'YYYY-MM-DD') < formatDate(new Date(), 'YYYY-MM-DD'))
  if (activeInstrument.indexProvider === "S&P Dow Jones Indices" && activeInstrument.rebalancingFrequency === "Daily") {
    isReadyForApproval = isReadyForApproval && every(weights, (weight) => weight.preWeight !== weight.postWeight)
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <TableHeader
        activeInstrument={activeInstrument}
        rebalances={rebalances}
        isExecutable={isExecutable}
        isUpdating={updating}
        tradeWasExecuted={tradeWasExecuted}
        onRefresh={refreshRebalanceValues}
        isRebalanceToday={isRebalanceToday}
      />
      <RebalanceTable
        onChange={handleOnChange}
        rebalances={rebalances}
        editable={isEditable}
      />
      <TableFooter
        onClickApproval={handleOnClickApproval}
        isReadyForApproval={isReadyForApproval}
        rebalances={rebalances}
        isExecutable={isExecutable}
        isUpdating={updating}
      />
    </>
  );
};

const renderWeightTable = ({
  activeInstrument,
  loading,
  isComputingRebalanceDates,
  setIsComputingRebalanceDates,
  rebalanceDates,
  weights,
  selectedDate,
  setSelectedDate,
  rebalances,
}) => {
  const rebalDates = rebalanceDates.map(
    date =>
      new Date(new Date(date).toLocaleDateString('en-US', { timeZone: 'CET' }))
  );
  const pastRebalances = rebalDates.filter(date => date <= new Date());
  const futureRebalances = rebalDates.filter(date => date > new Date());

  const highlightRebalances = [
    {
      'past-rebalance': pastRebalances
    },
    {
      'future-rebalance': futureRebalances
    }
  ];
  setTimeout(() => setIsComputingRebalanceDates(false), 1000)

  return loading || isComputingRebalanceDates ? (
    <Loading />
  ) : (
    <IndexWeightsTable
      activeInstrument={activeInstrument}
      rebalanceDates={highlightRebalances}
      weights={weights}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      rebalances={rebalances}
    />
  );
};

const Rebalance = ({ activeInstrument, currentUser }) => {
  const [rebalances, setRebalances] = useState([]);
  const orderedRebalances = Object.values(rebalances).sort((rebalanceA, rebalanceB) => {
    const tickerA = rebalanceA.crypto ? rebalanceA.crypto.ticker : rebalanceA.metal.ticker;
    const tickerB = rebalanceB.crypto ? rebalanceB.crypto.ticker : rebalanceB.metal.ticker;

    return tickerA.localeCompare(tickerB);
  })
  const [weights, setWeights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [rebalanceDates, setRebalanceDates] = useState([]);
  const [isComputingRebalanceDates, setIsComputingRebalanceDates] = useState(true)
  const [selectedDate, setSelectedDate] = useState(
    formatDate(new Date(), 'YYYY-MM-DD')
  );
  const [showRebalanceDetails, setShowRebalanceDetails] = useState(false);
  /*
    TODO: these flag and method must be removed as they are always false
    per: https://github.com/amun/onyx-back/blob/a50b548d56da8489ac42b2df1095ed9e6fe74025/frontend/src/pages/Admin/Rebalance/index.js#L243
  */
  const [isExecutable, setIsExecutable] = useState(false);

  useEffect(() => {
    if (activeInstrument) {
      setLoading(true);
      setIsExecutable(instrumentIsExecutable(activeInstrument));
      api
        .fetchRebalance(activeInstrument.id, selectedDate)
        .then(rebalancesResponse => {
          setRebalances(keyBy(rebalancesResponse, 'underlyingId'));
          setLoading(false);
        });
    }
    return () => false;
  }, [activeInstrument, selectedDate]);


  useEffect(() => {
    if (activeInstrument && selectedDate) {
      setLoading(true);
      api
        .fetchIndexWeights(activeInstrument.id, new Date(selectedDate))
        .then(weightsResponse => {
          setWeights(groupWeightsByUnderlying(weightsResponse, selectedDate));
          setLoading(false);
        });
    }
    return () => false;
  }, [activeInstrument, selectedDate]);


  useEffect(() => {
    if (activeInstrument && selectedDate) {
      setLoading(true);
      api
        .fetchRebalanceDates(
          activeInstrument.id,
          new Date(selectedDate).getFullYear()
        )
        .then(rebalanceDateResponse => {
          setRebalanceDates(rebalanceDateResponse.data);
          setLoading(false);
        });
    }
    return () => false;
  }, [activeInstrument, selectedDate]);

  const handleOnChange = async (newUnderlyingValue, options) => {
    if(!options.tradeStrategy){ 
      // NOTE: what kind of error do we have to show to the user in this case?
      toast.error(`Trade strategy is ${options.tradeStrategy}`);
    }
    let tradeStrategy = getTradeStrategy(options.tradeStrategy);

    Object.keys(rebalances).forEach((rebalance) => delete rebalances[rebalance].actualTrade)
    try {
      const { data: newRebalanceInfo } = await api.updateRebalance(
        newUnderlyingValue.id,
        {
          patch: Object.values({ 
            ...rebalances,
            [newUnderlyingValue.underlyingId]: newUnderlyingValue,
          }),
          options: 
          {
            instrumentId: activeInstrument.id,
            tradeStrategy
          }
        },
      );

      if (options.saveAuditLog) {
        api.createAuditLogEntry({
          type: 'USER',
          description: {
            user: currentUser.email,
            action: `Rebalance Override ${activeInstrument.ticker}`,
            details: {
              underlyingTicker: newUnderlyingValue.crypto?.ticker || newUnderlyingValue.metal?.ticker,
              newActualTrade: options.rebalance.actualTrade,
              newRebalanceInfo: newRebalanceInfo,
              updatedAt: new Date(),
            },
          },
        });
      }
      const newRebalances = newRebalanceInfo.reduce((o, key) => ({ ...o, [key.underlyingId]: key}), {});
      setRebalances(newRebalances);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleOnClickApproval = () => setShowRebalanceDetails(true);

  /**
  * @deprecated isExecutable was built for BlockFi S&P execution workflow but now disable for all products.
  * TODO: remove
  * @returns {false} always false
  */
  const instrumentIsExecutable = (instrument) => false;

  /**
   * @deprecated after deletion of the large if-branch which was controlled by `isExecutable`
   * this method became unusable. Can be removed after QA-confirms lack of regression
  * @returns {Promise} Bear in mind that async\await returns a promise
  */
  // eslint-disable-next-line no-unused-vars
  const fetchTradingDeskByName = async (tradingDeskName) => {
    const tradingDesks = await api.fetchCompaniesByRole('TRADING_DESK');
    
    /* 
    NOTE: switched from two filters to one find, 
    as it seems like we need to find only one (first) particular entry
    It also might save iterations
    */
    const activeTradingDesk = tradingDesks.find(company => {
      const isActive = get(company, 'extraData.isActiveTradingDesk', false);
      const hasName = company.name === tradingDeskName;
      return isActive && hasName;
    });
    return activeTradingDesk || false;
  };

  const cancelModal = () => {
    if(updating) return false;
    return setShowRebalanceDetails(false);
  };

  const refreshRebalanceValues = async () => {
    if (Object.keys(rebalances).length === 0) {
      setUpdating(true)
      return api.runTask('calculateNav', {
        valuationDate: selectedDate,
        instrumentId: activeInstrument.id
      })
      .then(() => api
        .fetchRebalance(activeInstrument.id, selectedDate)
        .then(rebalancesResponse => {
          setRebalances(keyBy(rebalancesResponse, 'underlyingId'));
          toast.success('Data refreshed');
        }))
      .catch((error) => toast.error(error.message))
      .finally(() => setUpdating(false))
    }
    
    // NOTE: here was isExecutable flag which was always 'false'
    // to avoid sideeffects I leave it here for now
    return false;
  };

  const fetchPermittedTradeCryptos = () => ['BTC', 'ETH'];

  const fetchIsRebalanceToday = (rebalances) => (
    Object.values(rebalances).some(rebalance => {
      const permittedCryptos = fetchPermittedTradeCryptos();
      const leftoverThreshold = 0.000000001;
      return permittedCryptos.includes(rebalance.crypto?.ticker) && 
        (rebalance.postWeight - rebalance.preWeight > leftoverThreshold || rebalance.postWeight - rebalance.preWeight < -leftoverThreshold)
    })
  );

  const confirmApproval = async () => {
    const approvedAt = new Date();

    setShowRebalanceDetails(false);
    setUpdating(true);
    try {
      /**
       * here was a if(isExecutable) branch, which was removed
       * per https://github.com/amun/onyx-back/blob/a50b548d56da8489ac42b2df1095ed9e6fe74025/frontend/src/pages/Admin/Rebalance/index.js#L243
      */
      const rebalanceIds = Object.values(rebalances).map(rebalance => rebalance.id);
      await api.approveRebalance(activeInstrument.id, {
        update: { approvedAt },
        ids: rebalanceIds
      });
      toast.success(`Rebalance Confirmed`);
      const rebalancesWithApproval = mapValues(rebalances, rebalance => ({ ...rebalance, approvedAt }))
      setRebalances(rebalancesWithApproval)
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <>
      <div className="mb-4">
        <ProductSelectorDropdown filter={({isIndex}) => isIndex} />
      </div>

      {activeInstrument && (
        <Card>
          <Card.Body>
            {renderWeightTable({
              activeInstrument,
              loading,
              isComputingRebalanceDates,
              setIsComputingRebalanceDates,
              handleOnChange,
              handleOnClickApproval,
              rebalanceDates,
              weights: Object.values(weights),
              selectedDate,
              setSelectedDate,
              updating,
              rebalances: orderedRebalances
            })}
          </Card.Body>
        </Card>
      )}
      <div className="my-5" />
      {activeInstrument && (
        <Card>
          <Card.Body>
            {renderRebalanceTable({
              activeInstrument,
              loading,
              handleOnChange,
              handleOnClickApproval,
              rebalances: orderedRebalances,
              selectedDate,
              weights,
              rebalanceDates,
              updating,
              isExecutable,
              tradeWasExecuted: orderedRebalances.some(rebalance => !!rebalance.tradeId),
              refreshRebalanceValues,
              isRebalanceToday: fetchIsRebalanceToday(weights),
            })}
            {activeInstrument && !loading && rebalances === [] && <NoResults />}
          </Card.Body>
        </Card>
      )}

      <RebalanceDetails
        show={showRebalanceDetails}
        onCancel={cancelModal}
        onConfirm={confirmApproval}
        rebalances={orderedRebalances}
        isExecutable={isExecutable}
        isUpdating={updating}
        tradeWasExecuted={orderedRebalances.some(rebalance => !!rebalance.tradeId)}
        onRefresh={refreshRebalanceValues}
        isRebalanceToday={fetchIsRebalanceToday(weights)}
      />
    </>
  );
};

export default connect(mapStateToProps)(Rebalance);
